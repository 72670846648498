import {useEffect} from 'react';
import {userManager} from '../../utils/UserManager';

const SilentCallback = () => {
    useEffect(() => {
        userManager.signinSilentCallback().catch(e => {console.log(e)});
    }, []);

    return null;
};

export default SilentCallback;
