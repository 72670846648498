import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { IngredientOption, Ingredient } from '../pages/RecipeDetail';
import '../resources/typeahead.css';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiInputAdornment: {
            positionStart: {
                marginRight: '2px',
            },
        },
        MuiOutlinedInput: {
            root: {
                "&&& $input": {
                    padding: "4px"
                }
            }
        }
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    editIcon: {
        color: 'grey',
        paddingLeft: '5px'
    },
    cancelIcon: {
        color: 'red',
    },
    wrapper: {
        minHeight: '200px',
    },
    root: {
        width: '100%',
    },
    inputRoot: {
        width: '100%',
    },
    simple: {
        width: '100%',
        backgroundColor: '#fff',
    },
});

type OwnProps = WithStyles<typeof styles> & {
    editable: boolean;
    callback: Function;
    ingredient: Ingredient;
    classname?: string;
    ingredientOptions: IngredientOption[];
    additionalParameters?: (string | number)[];
};

type Props = OwnProps;

const InlineEditTypeahead = ({ classes, editable, callback, classname, ingredient, ingredientOptions, additionalParameters }: Props) => {
    const [selectedIngredient, setSelectedIngredient] = useState(ingredientOptions.find(ingredientOption => ingredientOption.id === ingredient.priceSheetId));

    return (<React.Fragment>
        <span className={classes.wrapper}>
            {!editable && <Typography variant="body1" gutterBottom>
                {selectedIngredient?.name}
            </Typography>}
            {!!editable && <form className={classes.root} noValidate autoComplete="off">
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        options={ingredientOptions}
                        getOptionLabel={option => option.name}
                        blurOnSelect={true}
                        value={selectedIngredient}
                        multiple={false}
                        className={undefined === classname ? classes.simple : classes[classname]}
                        disableClearable={true}
                        onChange={(event, ingredientOption) => {
                            if (null !== ingredientOption) {
                                setSelectedIngredient(ingredientOption);

                                if (ingredientOption !== selectedIngredient) {
                                    if (undefined !== additionalParameters) {
                                        callback(ingredientOption, ...additionalParameters);
                                    } else {
                                        callback(ingredientOption);
                                    }
                                }
                            }
                        }}
                        selectOnFocus={true}
                        renderInput={params => <TextField {...params}
                            variant="outlined"
                            fullWidth
                        />}
                    />
                </ThemeProvider>
            </form>}
        </span>
    </React.Fragment>);
};

export default withStyles(styles)(InlineEditTypeahead);
