import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import OidcProvider from './components/Providers/OidcProvider';
import Main from './Main';
import SignInCallback from './pages/Oidc/SignInCallback';
import SignOutCallback from './pages/Oidc/SignOutCallback';
import SilentCallback from './pages/Oidc/SilentCallback';
import { userManager } from './utils/UserManager';

const Guard = () => {
    // add body style
    document.body.style.color = '#444444';
    document.body.style.fontSize = '16px';
    document.body.style.lineHeight = '1.5';

    return <OidcProvider userManager={userManager}>
        <Main />
    </OidcProvider>
};

const App = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/oidc/sign-in-callback" exact component={SignInCallback} />
            <Route path="/oidc/sign-out-callback" exact component={SignOutCallback} />
            <Route path="/oidc/silent-callback" exact component={SilentCallback} />
            <Route path="/" component={Guard} />
        </Switch>
    </BrowserRouter>
);

export default App;
