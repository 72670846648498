import {createStyles, Theme, WithStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

type OwnProps = WithStyles<typeof styles>;

type Props = OwnProps;

const FullPageLoadingIndicator = ({classes} : Props) => (
    <div className={classes.root}><CircularProgress/></div>
);

export default withStyles(styles)(FullPageLoadingIndicator);
