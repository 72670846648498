import { Button, createStyles, StyleRules, Theme, withStyles, WithStyles, Checkbox } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect } from 'react';
import { Recipe } from '../../pages/Recipes';
import { useHistory } from "react-router-dom";
import { apiEndpoint, useApiFetch } from '../Providers/OidcProvider';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const styles = (theme: Theme): StyleRules => createStyles({
    button: {
        color: theme.palette.text.secondary,
        background: theme.palette.secondary.dark,
        margin: theme.spacing(1),
    },
    buttonGroup: {
        textAlign: 'center',
    },
    checkbox: {
        color: '#00AEEF',
    },
    headerCell: {
        color: '#444444',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
        textAlign: 'left',
    },
    tableCell: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 400,
        textTransform: 'none',
        textAlign: 'left',
    },
    tableHead: {
        backgroundColor: '#8BEAFB',
        '& td': {
            color: theme.palette.primary.contrastText,
        },
    },
    tableFooter: {
        backgroundColor: theme.palette.primary.main,
        '& td': {
            color: theme.palette.primary.contrastText,
        },
    },
    recipeRowEmpty: {
        backgroundColor: theme.palette.primary.light,
        '& td': {
            color: theme.palette.primary.contrastText,
            fontWeight: 500,
        },
    },
    recipeRow: {
        backgroundColor: theme.palette.primary.light,
        '& td': {
            color: theme.palette.primary.contrastText,
            fontWeight: 500,
        },
        cursor: 'pointer',
    },
});

type Props = WithStyles<typeof styles> & {
    recipes: Recipe[];
    selectedJob: string;
    searchValue: string;
    setStoredSearch: Function;
};

const RecipeTable = ({ classes, recipes, selectedJob, searchValue, setStoredSearch }: Props) => {
    const apiFetch = useApiFetch();
    const [selectedRecipes, setSelectedRecipes] = useState<number[]>([]);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setSelectedRecipes([]);
    }, [selectedJob, searchValue]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const recipeId = parseInt(event.target.value);

        if (null === selectedRecipes) {
            setSelectedRecipes([recipeId]);
        } else if (selectedRecipes.includes(recipeId)) {
            const filteredRecipes = selectedRecipes.filter(id => id !== recipeId);
            setSelectedRecipes(filteredRecipes);
        } else {
            setSelectedRecipes([...selectedRecipes, parseInt(event.target.value)]);
        }
    };

    const handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const toggleAllRows = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (selectedRecipes.length !== recipes.length) {
            const addedRecipes = recipes.map(recipe => recipe.id);
            setSelectedRecipes(addedRecipes);
        } else {
            setSelectedRecipes([]);
        }
    };

    const downloadPdfs = async () => {
        const url = new URL('/v1/recipes/pdf', apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                recipeIds: selectedRecipes
            })
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        const linkSource = 'data:application/pdf;base64,' + data.pdfBase64;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = data.fileName + ' Cost.pdf';
        downloadLink.click();

        return enqueueSnackbar(data.fileName + " Cost.pdf has been downloaded.");
    };

    const downloadTrainingPdfs = async () => {
        const url = new URL('/v1/recipes/training-pdf', apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                recipeIds: selectedRecipes
            })
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        const linkSource = 'data:application/pdf;base64,' + data.pdfBase64;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = data.fileName + ' Training.pdf';
        downloadLink.click();

        return enqueueSnackbar(data.fileName + " Training.pdf has been downloaded.");
    };

    return (
        <Table size="small">
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell className={classes.headerCell}>
                        {(selectedRecipes.length === recipes.length || 0 === selectedRecipes.length) && <Checkbox
                            checked={(selectedRecipes.length === recipes.length && recipes.length > 0)}
                            onClick={toggleAllRows}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className={classes.checkbox}
                            readOnly
                        />}
                        {!(selectedRecipes.length === recipes.length || 0 === selectedRecipes.length) && <Checkbox
                            checked={selectedRecipes.length === recipes.length}
                            onClick={toggleAllRows}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className={classes.checkbox}
                            indeterminate
                            readOnly
                        />}
                    </TableCell>
                    <TableCell className={classes.headerCell}>Job Name</TableCell>
                    <TableCell className={classes.headerCell}>Drink Name</TableCell>
                    <TableCell className={classes.headerCell}>Glassware</TableCell>
                    <TableCell className={classes.headerCell}>Drink Style</TableCell>
                    <TableCell className={classes.headerCell}>Status</TableCell>
                    <TableCell className={classes.headerCell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {0 === recipes.length && <React.Fragment key="ROW-0">
                    <TableRow className={classes.recipeRowEmpty}>
                        <TableCell>NO RECIPES FOUND</TableCell>
                    </TableRow>
                </React.Fragment>}
                {recipes.map(recipe => {
                    return (
                        <React.Fragment key={'ROW-' + recipe.id}>
                            <TableRow className={classes.recipeRow} onClick={e => {
                                setStoredSearch(searchValue);
                                history.push('/recipe/' + recipe.id);
                            }}>
                                <TableCell className={classes.tableCell}>
                                    <Checkbox
                                        checked={selectedRecipes ? selectedRecipes.includes(recipe.id) : false}
                                        onClick={handleCheckboxClick}
                                        onChange={handleCheckboxChange}
                                        value={recipe.id}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        className={classes.checkbox}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>{recipe.jobName}</TableCell>
                                <TableCell className={classes.tableCell}>{recipe.name}</TableCell>
                                <TableCell className={classes.tableCell}>{recipe.glassware}</TableCell>
                                <TableCell className={classes.tableCell}>{recipe.drinkStyle}</TableCell>
                                <TableCell className={classes.tableCell}>{recipe.statusClient}</TableCell>
                                <TableCell className={classes.tableCell}><FontAwesomeIcon icon={faChevronRight} /></TableCell>
                            </TableRow>
                        </React.Fragment>
                    );
                })}
            </TableBody>
            <TableFooter className={classes.tableFooter}>
                <TableRow>
                    <TableCell colSpan={7}>
                        {selectedRecipes.length > 0 && selectedRecipes.length <= 10 &&
                            <div className={classes.buttonGroup}>
                                <Button variant="contained" onClick={downloadPdfs} className={classes.button}>
                                    DOWNLOAD COST PDF(S)
                                </Button>
                                <Button variant="contained" onClick={downloadTrainingPdfs} className={classes.button}>
                                    DOWNLOAD TRAINING PDF(S)
                                </Button>
                            </div>}
                        {selectedRecipes.length > 0 && selectedRecipes.length > 10 &&
                            <div className={classes.buttonGroup}>A MAXIMUM OF 20 PDFS MAY BE DOWNLOADED AT A TIME</div>}
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

export default withStyles(styles)(RecipeTable);
