
import { createStyles, Theme, WithStyles, InputAdornment, OutlinedInput } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { useState, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            root: {
                color: 'black',
            },
        },
        MuiInputAdornment: {
            positionStart: {
                marginRight: '2px',
            },
        },
        MuiOutlinedInput: {
            root: {
                "&&& $input": {
                    paddingTop: "14px",
                    paddingBottom: "14px",
                }
            }
        }
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    editIcon: {
        color: 'grey',
        paddingLeft: '5px'
    },
    cancelIcon: {
        color: 'red',
    },
    root: {
        color: 'black',
    },
    wrapper: {
        minHeight: '200px',
    },
    none: {
        display: 'none',
    },
    simple: {
        width: '80px',
        backgroundColor: '#fff',
        textAlign: 'left',
    },
    headerText: {
        color: 'white'
    },
});

type OwnProps = WithStyles<typeof styles> & {
    editable: boolean;
    value: string | number;
    classname?: string;
    prefix?: string;
    callback: Function;
    additionalParameters?: (string | number)[];
};

type Props = OwnProps;

const InlineEditCurrency = ({ classes, editable, value, callback, additionalParameters, classname, prefix }: Props) => {
    const [originalValue, setOriginalValue] = useState(value);
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const handleBlur = (e: React.FormEvent<EventTarget>) => {
        let value = localValue;

        if ('' === value) {
            setLocalValue(originalValue);
        } else {
            if (value !== originalValue) {
                if (undefined !== additionalParameters) {
                    callback(value, ...additionalParameters);
                } else {
                    callback(value);
                }
            }
        }
    }

    if (originalValue !== value) {
        setOriginalValue(value);
        setLocalValue(value);
    }

    return (<React.Fragment>
        <span className={classes.wrapper}>
            {!editable && <Typography variant="body1" className={classes.wrapped} gutterBottom>
                {prefix}{localValue}
            </Typography>}
            {!!editable && <form className={classes.root} noValidate autoComplete="off">
                <button type="submit" disabled className={classes.none} aria-hidden="true"></button>
                <ThemeProvider theme={theme}>
                    <OutlinedInput
                        fullWidth
                        inputRef={inputRef}
                        className={undefined === classname ? classes.simple : classes[classname]}
                        onBlur={handleBlur}
                        value={localValue}
                        onChange={e => setLocalValue(e.currentTarget.value)}
                        onKeyDown={e => {
                            if ('Enter' === e.key) {
                                e.currentTarget.blur();
                            }
                        }}
                        color="primary"
                        startAdornment={<InputAdornment
                            position="start"
                        >$</InputAdornment>}
                    />
                </ThemeProvider>
            </form>}
        </span>
    </React.Fragment>);
};

export default withStyles(styles)(InlineEditCurrency);
