import {Typography} from '@material-ui/core';
import React from 'react';

const NotFound = () => (
    <React.Fragment>
        <Typography variant="h4">File not found</Typography>
        <Typography variant="body1">
            The requested file could not be found.
        </Typography>
    </React.Fragment>
);

export default NotFound;
