import { InputLabel, Select, StyleRules, TableContainer, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppToolbar from '../components/AppToolbar';
import { IngredientOption, StatusOption } from '../pages/RecipeDetail';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import RecipeTable from '../components/Recipe/RecipeTable';
import SearchInput from '../components/SearchInput';
import { useParams } from 'react-router-dom';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                verticalAlign: 'middle',
            },
        },
        MuiSelect: {
            select: {
                paddingLeft: '10px',
            },
        }
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    searchBar: {
        backgroundColor: '#6D6D6D',
        textAlign: 'center',
        padding: '20px 15px 15px',
    },
    searchLabel: {
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
        textAlign: 'right',
    },
    searchText: {
        color: '#ffffff',
        display: 'inline-block',
        marginLeft: '10px',
        marginTop: '8px',
        marginRight: '4px',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
        textAlign: 'right',
        paddingRight: '10px',
        verticalAlign: 'top!important'
    },
    jobText: {
        marginTop: '8px',
        color: '#ffffff',
        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '4px',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
        textAlign: 'right',
        verticalAlign: 'top!important'
    },
    searchInput: {
        color: '#ffffff',
        backgroundColor: "#ffffff",
        marginLeft: '10px',
        height: '35px',
        width: '220px',
        padding: '2px',
    },
    select: {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.dark,
        height: '35px',
        marginLeft: '10px',
        minWidth: '200px',
        textAlign: 'left',
        verticalAlign: 'top!important'
    },
    error: {
        textAlign: "center",
        color: 'red'
    }
});

type OwnProps = WithStyles<typeof styles> & {
    selectedJob: string;
    setSelectedJob: Function;
    jobs: Job[] | null;
    recipes: Recipe[] | null;
    storedSearch: string;
    setStoredSearch: Function;
    ingredientOptions: IngredientOption[] | null;
    statuses: StatusOption[] | null;
};

type Props = OwnProps;

export type Recipe = {
    id: number;
    jobId: number;
    name: string;
    drinkStyle: string;
    ingredient: string;
    color: string;
    glassware: string;
    jobName: string;
    keywords: string;
    statusClient: string;
};

export type Job = {
    jobId: number;
    jobName: string;
};

type MyOption = { label: string, value: number }

const Recipes = ({ classes, selectedJob, setSelectedJob, jobs, recipes, storedSearch, setStoredSearch, ingredientOptions, statuses }: Props) => {
    let { jobId } = useParams()
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('');

    const handleJobChange = (event: ChangeEvent<{ value: unknown }>): void => {
        setStoredSearch(searchValue);
        setSelectedJob(event.target.value as string);
    };

    const handleStatusChange = (event: ChangeEvent<{ value: unknown }>): void => {
        setStatus(event.target.value as string);
    };

    useEffect(() => {
        if (undefined !== jobId) {
            setSelectedJob(jobId);
        }
    }, [jobId, setSelectedJob]);


    useEffect(() => {
        setSearchValue(storedSearch);
    }, [storedSearch]);

    let displayedRecipes: Recipe[] = [];

    if (null !== recipes) {
        recipes.forEach((recipe) => {
            const searchTokens = searchValue.trim().split(' ');

            let foundTokens = 0;

            searchTokens.forEach((searchToken) => {
                if ((0 === parseInt(selectedJob) || recipe.jobId === parseInt(selectedJob)) &&
                    (
                        recipe.keywords.toLowerCase().includes(searchToken.toLowerCase()) ||
                        recipe.name.toLowerCase().includes(searchToken.toLowerCase()) ||
                        recipe.glassware.toLowerCase().includes(searchToken.toLowerCase()) ||
                        recipe.jobName.toLowerCase().includes(searchToken.toLowerCase()) ||
                        recipe.drinkStyle.toLowerCase().includes(searchToken.toLowerCase())
                    ) &&
                    ('' === searchValue || '' !== searchToken) &&
                    ('' === status || recipe.statusClient === status) &&
                    false === displayedRecipes.includes(recipe)
                ) {
                    foundTokens++;
                }
            });

            if (foundTokens === searchTokens.length) {
                displayedRecipes.push(recipe);
            }
        });
    }

    return (
        <React.Fragment>
            <AppToolbar detail={false}></AppToolbar>
            {undefined === jobId && <section className={classes.searchBar}>
                <form className={classes.form} id="addItemForm">
                    <InputLabel className={classes.searchText}>Search</InputLabel>
                    {<SearchInput searchValue={searchValue} setSearchValue={setSearchValue}></SearchInput>}
                    <InputLabel className={classes.jobText}>Job</InputLabel>
                    <ThemeProvider theme={theme}>
                        <Select
                            className={classes.select}
                            value={selectedJob}
                            onChange={handleJobChange}
                            autoWidth
                        >
                            <MenuItem key={"job-" + 0} value={0}>All</MenuItem>
                            {null !== jobs && jobs.map(job => <MenuItem key={"job-" + job.jobId} value={job.jobId}>{job.jobName}</MenuItem>)}
                        </Select>
                    </ThemeProvider>
                    <InputLabel className={classes.jobText}>Status</InputLabel>
                    <ThemeProvider theme={theme}>
                        <Select
                            className={classes.select}
                            value={status}
                            onChange={handleStatusChange}
                            autoWidth
                            displayEmpty
                        >
                            <MenuItem key={"status-" + 0} value={''}>All</MenuItem>
                            {null !== statuses && statuses.map(status => <MenuItem key={"status-" + status.value} value={status.value}>{status.value}</MenuItem>)}
                        </Select>
                    </ThemeProvider>
                </form>
            </section>}
            <TableContainer component={Paper}>
                {recipes === null && <InlineLoadingIndicator />}
                {recipes !== null && <RecipeTable
                    recipes={displayedRecipes}
                    selectedJob={selectedJob}
                    searchValue={searchValue}
                    setStoredSearch={setStoredSearch}
                />}
            </TableContainer>
        </React.Fragment>
    );
};

export default withStyles(styles)(Recipes);
