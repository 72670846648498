import React from 'react';
import { createStyles, Theme, WithStyles, Input } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                backgroundColor: '#ffffff',
                fontSize: '15px',
                fontWeight: 400,
                textTransform: 'uppercase',
                height: '21px'
            },
        },
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    MuiInputBase: {
        input: {
        }
    }
});

type OwnProps = WithStyles<typeof styles> & {
    searchValue: string;
    setSearchValue: Function;
};

type Props = OwnProps;

const SearchInput = ({ classes, searchValue, setSearchValue }: Props) => (
    <ThemeProvider theme={theme}>
        <Input
            inputProps={{ 'aria-label': 'search-for-drinks' }}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
        />
    </ThemeProvider>
);

export default withStyles(styles)(SearchInput);
