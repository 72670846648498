import React, { useState, useCallback, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import NotFound from './pages/NotFound';
import RecipeDetail, { StatusOption, IngredientOption } from './pages/RecipeDetail';
import Recipes from './pages/Recipes';
import { Recipe } from './pages/Recipes';
import { apiEndpoint, useApiFetch } from './components/Providers/OidcProvider';
import { useSnackbar } from 'notistack';

const Main = () => {
    const [selectedJob, setSelectedJob] = useState<string>('0');
    const [storedSearch, setStoredSearch] = useState<string>('');
    const [jobs, setJobs] = useState<Recipe[] | null>(null);
    const [recipes, setRecipes] = useState<Recipe[] | null>(null);
    const [ingredientOptions, setIngredientOptions] = useState<IngredientOption[] | null>(null);
    const [statuses, setStatuses] = useState<StatusOption[] | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const apiFetch = useApiFetch();

    const loadRecipes = useCallback(async () => {
        setRecipes(null);

        const url = new URL('/v1/recipes', apiEndpoint);

        const response = await apiFetch(url.toString());
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipes(data);

    }, [setRecipes, apiFetch, enqueueSnackbar]);

    const loadIngredientOptions = useCallback(async () => {
        const url = new URL('/v1/ingredients', apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        const CustomerPriceSheet = data.CustomerPriceSheet;
        const NationalAveragePriceSheet = data.NationalAveragePriceSheet;
        setIngredientOptions(CustomerPriceSheet.concat(NationalAveragePriceSheet));
    }, [apiFetch, enqueueSnackbar]);

    const loadStatusOptions = useCallback(async () => {
        const url = new URL('/v1/status', apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }
        
        setStatuses(data);
    }, [apiFetch, enqueueSnackbar]);

    useEffect(() => {
        loadIngredientOptions();
    }, [loadIngredientOptions]);

    useEffect(() => {
        loadStatusOptions();
    }, [loadStatusOptions]);

    useEffect(() => {
        loadRecipes();
    }, [loadRecipes]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
    }, []);

    useEffect(() => {
        if (null !== recipes) {
            const filteredRecipes = recipes.filter((recipe, index, self) =>
                index === self.findIndex((t) => (
                    t.jobId === recipe.jobId
                ))
            );

            setJobs(filteredRecipes);
        }
    }, [recipes]);

    const handleKeyDown = (e: KeyboardEvent): any => {
        // don't navigate back on backspace
        if (e.keyCode === 8 && e.target === document.body) {
            e.preventDefault();
        }

        // don't submit on return
        if (e.keyCode === 13 && document.activeElement instanceof HTMLElement) {
            e.preventDefault();
            document.activeElement.blur();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
    }, []);

    return (
        <div>
            <main>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => <Recipes
                            jobs={jobs}
                            recipes={recipes}
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                            storedSearch={storedSearch}
                            setStoredSearch={setStoredSearch}
                            ingredientOptions={ingredientOptions}
                            statuses={statuses}
                        />} />
                    <Route path="/job/:jobId" exact render={() => <Recipes
                        jobs={jobs}
                        recipes={recipes}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        storedSearch={storedSearch}
                        setStoredSearch={setStoredSearch}
                        ingredientOptions={ingredientOptions}
                        statuses={statuses}
                    />} />
                    <Route path="/recipe/:recipeId" exact render={() => <RecipeDetail
                        ingredientOptions={ingredientOptions}
                        statuses={statuses}
                        recipes={recipes}
                        setRecipes={setRecipes}
                    />} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </main>
        </div>
    );
};

export default Main;
