import {Log, User, UserManager} from 'oidc-client';
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import FullPageLoadingIndicator from '../FullPageLoadingIndicator';

type Props = {
    userManager : UserManager;
    children? : ReactNode;
};

Log.logger = console;

const oidcContext = createContext<User | null>(null);

const OidcProvider = ({userManager, children} : Props) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const performSignInRedirect = async () => {
            const {location} = window;
            await userManager.signinRedirect({
                data: {returnTo: location.pathname + location.search + location.hash},
            });
        };

        const performSignIn = async () => {
            await userManager.clearStaleState();

            try {
                await userManager.signinSilent();
            } catch (e) {
                await performSignInRedirect();
            }
        };

        const handleUserLoaded = (user : User) => {
            setUser(user);
        };

        const handleUserUnloaded = () => {
            setUser(null);
        };

        const handleAccessTokenExpired = async () => {
            setUser(null);
            await performSignIn();
        };

        const handleSilentRenewError = async () => {
            await performSignInRedirect();
        };

        const handleUserSignedOut = async () => {
            await performSignInRedirect();
        };

        const handleUserSessionChanged = async () => {
            setUser(null);
            await performSignIn();
        };

        userManager.events.addUserLoaded(handleUserLoaded);
        userManager.events.addUserUnloaded(handleUserUnloaded);
        userManager.events.addAccessTokenExpired(handleAccessTokenExpired);
        userManager.events.addSilentRenewError(handleSilentRenewError);
        userManager.events.addUserSignedOut(handleUserSignedOut);
        userManager.events.addUserSessionChanged(handleUserSessionChanged);

        (async () => {
            const user = await userManager.getUser();

            if (user !== null && !user.expired) {
                setUser(user);
                return;
            }

            return performSignIn();
        })();

        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
            userManager.events.removeUserUnloaded(handleUserUnloaded);
            userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
            userManager.events.removeSilentRenewError(handleSilentRenewError);
            userManager.events.removeUserSignedOut(handleUserSignedOut);
            userManager.events.removeUserSessionChanged(handleUserSessionChanged);
        };
    }, [userManager]);

    if (!user) {
        return <FullPageLoadingIndicator/>;
    }

    return (
        <oidcContext.Provider value={user}>
            {children}
        </oidcContext.Provider>
    );
};

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT!;

export const useApiFetch = () => {
    const user = useContext(oidcContext);

    if (!user) {
        throw new Error('Context was used before initial load');
    }

    return useCallback(async (url : string, init? : RequestInit) : Promise<Response> => {
        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${user.access_token}`);
        init.headers.append('Content-Type', 'application/json');

        return fetch(url, init);
    }, [user]);
};

export default OidcProvider;
