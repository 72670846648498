import { createStyles, Theme, WithStyles, OutlinedInput } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme): StyleRules => createStyles({
    editIcon: {
        color: 'grey',
        paddingLeft: '5px'
    },
    cancelIcon: {
        color: 'red',
    },
    wrapper: {
        overflow: 'hidden',
    },
    textarea: {
        width: '100%',
        float: 'left',
        backgroundColor: '#fff',
        border: 0,
        height: '100%',
        boxSizing: 'border-box',
        fontSize: '12pt',
        fontWeight: 400,
    },
    wrapped: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
});

type OwnProps = WithStyles<typeof styles> & {
    editable: boolean;
    value: string;
    callback: Function;
};

type Props = OwnProps;

const InlineEditTextArea = ({ classes, editable, value, callback }: Props) => {
    const [localValue, setLocalValue] = useState(value);

    const handleBlur = () => {
        if ('' === localValue) {
            setLocalValue(value);
        } else {
            if (value !== localValue) {
                callback(localValue);
            }
        }
    }

    return (<React.Fragment>
        <div className={classes.wrapper}>
            {!editable && <Typography variant="body1" className={classes.wrapped} gutterBottom>
                {localValue}
            </Typography>}
            {!!editable && <form className={classes.root} noValidate autoComplete="off">
                <OutlinedInput
                    multiline
                    className={classes.textarea}
                    aria-label="maximum height"
                    value={localValue}
                    onChange={e => setLocalValue(e.currentTarget.value)}
                    onBlur={handleBlur}
                />
            </form>}
        </div>
    </React.Fragment>);
};

export default withStyles(styles)(InlineEditTextArea);
