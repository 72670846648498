import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import FullPageLoadingIndicator from '../../components/FullPageLoadingIndicator';
import {userManager} from '../../utils/UserManager';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    }
}));

const SignInCallback = () => {
    const classes = useStyles();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    useEffect(() => {
        userManager.signinRedirectCallback().then(user => {
            history.push((user.state && user.state.returnTo) || '/');
        }).catch(error => {
            return setErrorMessage('Something went wrong. The login form could be expired.');
        });
    }, [history]);

    if (!errorMessage) {
        return <FullPageLoadingIndicator/>;
    }

    return (
        <Container maxWidth="sm" className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom>Authentication Error</Typography>
                <Typography variant="body1">{errorMessage}</Typography>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => userManager.signinRedirect()}
                >
                    Return to login
                </Button>
            </Paper>
        </Container>
    );
};

export default SignInCallback;
