import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import FullPageLoadingIndicator from '../../components/FullPageLoadingIndicator';
import {userManager} from '../../utils/UserManager';

const SignOutCallback = () => {
    const history = useHistory();

    useEffect(() => {
        userManager.signoutRedirectCallback().then(user => {
            history.push('/');
        }).catch(error => {
            history.push('/');
        });
    }, [history]);

    return <FullPageLoadingIndicator/>;
};

export default SignOutCallback;
