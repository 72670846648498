import { createStyles, Theme, WithStyles, Button } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../resources/images/logo-phcp-white.png';
import { useHistory } from "react-router-dom";
import { userManager } from '../utils/UserManager';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { apiEndpoint, useApiFetch } from '../components/Providers/OidcProvider';

const theme = createMuiTheme({
    overrides: {
        MuiAppBar: {
            colorDefault: {
                backgroundColor: "#00598A"
            },
        },
        MuiButton: {
            label: {
                marginLeft: "15px"
            },
        }
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    flexContainer: {
        flex: 1,
        justifyContent: 'space-between'
    },
    flexItem: {
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '185px',
        height: '50px',
    },
    toolbarText: {
        fontSize: "20px",
        fontWeight: 400,
        color: "#ffffff",
        textAlign: "center",
    },
    button: {
        border: "none",
        fontSize: "16px",
        textTransform: "uppercase",
        color: "#ffffff",
    },
});

type OwnProps = WithStyles<typeof styles> & {
    detail?: boolean | null;
    recipeId?: string;
    recipeName?: string
};

type Props = OwnProps;

const AppToolbar = ({ classes, detail, recipeId, recipeName }: Props) => {
    let history = useHistory();
    const apiFetch = useApiFetch();
    const { enqueueSnackbar } = useSnackbar();

    const duplicateRecipe = async () => {
        const url = new URL(`/v1/recipes/${recipeId}/duplicate`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'POST',
        });

        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        history.push('/recipe/' + data.id)
    };

    const downloadRecipePdf = async () => {
        const url = new URL('/v1/recipes/pdf', apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                recipeIds: [Number(recipeId)]
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        const linkSource = 'data:application/pdf;base64,' + data.pdfBase64;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = data.fileName + ' Cost.pdf';
        downloadLink.click();

        return enqueueSnackbar(data.fileName + " Cost.pdf has been downloaded.");
    };

    const downloadTrainingPdf = async () => {
        const url = new URL('/v1/recipes/training-pdf', apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                recipeIds: [Number(recipeId)]
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        const linkSource = 'data:application/pdf;base64,' + data.pdfBase64;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = data.fileName + ' Training.pdf';
        downloadLink.click();

        return enqueueSnackbar(data.fileName + " Training.pdf has been downloaded.");
    };

    return <React.Fragment>
        <ThemeProvider theme={theme}>
            <AppBar position="static" color="default">
                <Toolbar className={classes.flexContainer}>
                    <div className={classes.flexItem}>
                        <img className={classes.logo} src={logo} alt="logo" />
                    </div>

                    {true !== detail && <div className={classes.flexItem}>
                        <Typography variant="h6" className={classes.toolbarText} onClick={e => history.push('/')}>
                            RECIPE LIST
                        </Typography>
                    </div>}

                    {true === detail && <div className={classes.flexItem}>
                        <Typography variant="body1" className={classes.toolbarText} gutterBottom>
                            {recipeName}
                        </Typography>
                    </div>}

                    {true !== detail && <div className={classes.flexItem}>
                        <Button onClick={async () => await userManager.signoutRedirect()} className={classes.button}>
                            Logout
                        </Button>
                    </div>}

                    {true === detail && <div className={classes.flexItem}>
                        <Button onClick={e => history.push('/')} className={classes.button}>
                            Recipe List
                        </Button>
                        <Button onClick={downloadRecipePdf} className={classes.button}>
                            Save as Cost Pdf
                        </Button>
                        <Button onClick={downloadTrainingPdf} className={classes.button}>
                            Save as Training Pdf
                        </Button>
                        <Button onClick={duplicateRecipe} className={classes.button}>
                            Duplicate
                        </Button>
                        <Button onClick={async () => await userManager.signoutRedirect()} className={classes.button}>
                            Logout
                        </Button>
                    </div>}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    </React.Fragment>
};

export default withStyles(styles)(AppToolbar);
