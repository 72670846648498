import {createStyles, Theme, WithStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: theme.spacing(2, 0),
    },
});

type OwnProps = WithStyles<typeof styles> & {
    className? : string;
};

type Props = OwnProps;

const InlineLoadingIndicator = ({classes, className} : Props) => (
    <div className={classNames(classes.root, className)}><CircularProgress/></div>
);

export default withStyles(styles)(InlineLoadingIndicator);
