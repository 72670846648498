import { createStyles, StyleRules, Theme, withStyles, WithStyles, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import Typography from '@material-ui/core/Typography';
import { useParams } from "react-router-dom";
import InlineEditTextArea from '../components/InlineEditTextArea';
import InlineEditCurrency from '../components/InlineEditCurrency';
import InlineEditTypeahead from '../components/InlineEditTypeahead';
import { apiEndpoint, useApiFetch } from '../components/Providers/OidcProvider';
import AppToolbar from '../components/AppToolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InlineEditText from '../components/InlineEditText';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {Recipe as ListRecipe } from '../pages/Recipes';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                verticalAlign: 'middle',
            },
        },
        MuiSelect: {
            select: {
                paddingLeft: '10px',
            },
        }
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    outerContainer: {
        padding: '8px',
        overflowX: 'hidden'
    },
    upperLeftContainer: {
        padding: '30px 20px'
    },
    innerContainer: {
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    button: {
        color: theme.palette.text.secondary,
        background: theme.palette.secondary.dark,
        margin: theme.spacing(1),
    },
    tableCell: {
        backgroundColor: 'rgba(0, 174, 239, 0.05)',
        border: 'none',
        borderBottom: '1px solid #ffffff',
        color: '#444444',
        fontSize: '16px',
    },
    textAreaTableCell: {
        backgroundColor: 'rgba(0, 174, 239, 0.05)',
        border: 'none',
        borderBottom: '1px solid #ffffff',
        color: '#444444',
        fontSize: '16px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    tableFootCell: {
        backgroundColor: 'rbg(20, 0, 0)',
        color: '#000000',
        textAlign: 'right',
    },
    blueLight: {
        backgroundColor: '#00AEEF',
        border: 'none',
        borderBottom: '1px solid #ffffff',
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
    },
    blueDark: {
        backgroundColor: '#00598A',
        border: 'none',
        borderBottom: '1px solid #ffffff',
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: 400,
        textTransform: 'uppercase',
    },
    buttonGroup: {
        textAlign: 'center',
    },
    drinkImage: {
        height: '100%',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    suggestedSellingPricePaper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        minHeight: '225px',
    },
    suggestedSellingPricePaperView: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        height: '140px',
    },
    suggestedSellingPriceText: {
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    suggestedSellingPriceView: {
        paddingBottom: '1px',
    },
    quantity: {
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    quantityView: {
        paddingBottom: '1px',
    },
    recipeHeaderPaper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        background: theme.palette.primary.dark,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    recipeHeader: {
        color: theme.palette.text.secondary,
        fontWeight: 800,
    },
    blueText: {
        color: theme.palette.primary.contrastText,
        fontWeight: 800,
    },
    drinkPreview: {
        maxHeight: '550px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageContainer: {
        textAlign: 'center',
    },
    drinkPhoto: {
        padding: theme.spacing(1),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        height: '98%',
    },
    column: {
        display: "flex",
        flexDirection: 'column',
        width: '48%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    wrapped: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    pricesAndMargin: {
        height: '100px'
    },
    select: {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.dark,
        height: '35px',
        marginLeft: '10px',
        minWidth: '200px',
        textAlign: 'left',
        verticalAlign: 'top!important'
    },
});

export type Garnish = {
    priceSheetId: number;
    id: number;
    name: string;
    quantity: string;
    cost: number;
};

export type Ingredient = {
    priceSheetId: number;
    id: number;
    name: string;
    quantity: string;
    cost: number;
};

export type IngredientOption = {
    id: number;
    name: string;
    price: string;
};

export type StatusOption = {
    value: string;
};

export type SellingPrice = {
    price1: number;
    price2: number;
    price3: number;
    margin1: number;
    margin2: number;
    margin3: number;
};

export type Recipe = {
    clientNote: string;
    description: string;
    directions: string;
    drinkStyle: string;
    garnish: Garnish[];
    garnishCostTotal: string;
    glassware: string;
    id: number;
    imageBase64: string;
    ingredientCostTotal: string;
    ingredients: Ingredient[];
    ingredientOptions: IngredientOption[];
    isEditable: boolean;
    keywords: string;
    method: string;
    name: string;
    suggestedSellingPrice: SellingPrice;
    statusClient: string;
    createdBy: string;
};

type OwnProps = WithStyles<typeof styles> & {
    ingredientOptions: IngredientOption[] | null;
    statuses: StatusOption[] | null;
    recipes: ListRecipe[] | null;
    setRecipes: (recipes: ListRecipe[]) => void;
};

type Props = OwnProps;

const RecipeDetail = ({ classes, ingredientOptions, statuses, recipes, setRecipes }: Props) => {
    const apiFetch = useApiFetch();
    const { recipeId } = useParams();
    const [recipe, setRecipe] = useState<Recipe | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const loadRecipe = useCallback(async () => {
        setRecipe(null);

        const url = new URL('/v1/recipes/' + recipeId, apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    }, [setRecipe, recipeId, apiFetch, enqueueSnackbar]);

    useEffect(() => {
        loadRecipe();
    }, [loadRecipe]);

    if (null === recipe || null === ingredientOptions) {
        return <InlineLoadingIndicator />;
    }

    const setIngredient = async (ingredientOption: IngredientOption, oldIngredientOption: number) => {
        const url = new URL(`/v1/recipes/${recipeId}/ingredients/${oldIngredientOption}/option`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                ingredientOptionId: ingredientOption.id
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    };

    const setIngredientCost = async (cost: number, ingredientId: number) => {
        const url = new URL(`/v1/recipes/${recipeId}/ingredients/${ingredientId}/price`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                ingredientPrice: cost
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    };

    const updateClientNote = async (clientNote: string) => {
        const url = new URL(`/v1/recipes/${recipeId}/client-note`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                note: clientNote
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    };

    const updateRecipeName = async (name: string) => {
        const url = new URL(`/v1/recipes/${recipeId}/name`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                name: name
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    };

    const updateSuggestedSellingPrices = async (price: number, position: number) => {
        if (recipe === null) {
            return;
        }

        const url = new URL(`/v1/recipes/${recipeId}/suggested-selling-price`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                price1: position === 1 ? price : recipe.suggestedSellingPrice.price1.toFixed(2),
                price2: position === 2 ? price : recipe.suggestedSellingPrice.price2.toFixed(2),
                price3: position === 3 ? price : recipe.suggestedSellingPrice.price3.toFixed(2),
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);
    };

    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        updateStatusClient(event.target.value as string);
    };

    const updateStatusClient = async (status: string) => {
        const url = new URL(`/v1/recipes/${recipeId}/status`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'PATCH',
            body: JSON.stringify({
                status: status,
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar(data.hint, { variant: 'error' });
        }

        setRecipe(data);

        if (recipes !== null) {
            const newRecipes = recipes.map(recipe => {
                if (recipeId !== undefined && recipe.id === parseInt(recipeId)) {
                    recipe.statusClient = status;
                }

                return recipe;
            });

            setRecipes(newRecipes);
        }
    };

    return (
        <React.Fragment>
            <AppToolbar
                detail={true}
                recipeId={recipeId}
                recipeName={recipe.name}
            ></AppToolbar>

            <Grid container spacing={0} className={classes.outerContainer}>
                <Grid item xs={7} className={classes.upperLeftContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.blueLight}>Drink Name</TableCell>
                                    <TableCell className={classes.blueLight}>Glass</TableCell>
                                    <TableCell className={classes.blueLight}>Method</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={recipe.id}>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <InlineEditText
                                            editable={recipe.isEditable}
                                            callback={updateRecipeName}
                                            value={recipe.name}
                                        />
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>{recipe.glassware}</TableCell>
                                    <TableCell align="left" className={classes.tableCell}>{recipe.method}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.blueLight}>Quantity</TableCell>
                                    <TableCell align="left" className={classes.blueLight}>Ingredients</TableCell>
                                    <TableCell align="right" className={classes.blueLight}>Cost</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recipe.ingredients && recipe.ingredients.map((ingredient) => (
                                    <TableRow key={'ingredient-' + ingredient.id}>
                                        <TableCell align="center" className={classes.tableCell}>
                                            <Typography
                                                variant="subtitle1"
                                                className={recipe.isEditable ? classes.quantity : classes.quantityView}>
                                                {ingredient.quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableCell}>
                                            <InlineEditTypeahead
                                                ingredientOptions={ingredientOptions}
                                                editable={recipe.isEditable}
                                                callback={setIngredient}
                                                ingredient={ingredient}
                                                additionalParameters={[ingredient.id]}
                                            />
                                        </TableCell>
                                        <TableCell align="right" className={classes.tableCell}>
                                            <InlineEditCurrency
                                                editable={recipe.isEditable}
                                                callback={setIngredientCost}
                                                value={ingredient.cost.toFixed(2)}
                                                additionalParameters={[ingredient.id]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={2} align="right" className={classes.tableFootCell}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Total Ingredient Cost
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableFootCell}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {recipe.ingredientCostTotal}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.blueLight}>Quantity</TableCell>
                                    <TableCell align="left" className={classes.blueLight}>Ingredients</TableCell>
                                    <TableCell align="right" className={classes.blueLight}>Cost</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recipe.garnish && recipe.garnish.map((garnish) => (
                                    <TableRow key={'garnish-' + garnish.id}>
                                        <TableCell align="center" className={classes.tableCell}>
                                            <Typography
                                                variant="subtitle1"
                                                className={recipe.isEditable ? classes.quantity : classes.quantityView}>
                                                {garnish.quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableCell}>
                                            <InlineEditTypeahead
                                                ingredientOptions={ingredientOptions}
                                                editable={recipe.isEditable}
                                                callback={setIngredient}
                                                ingredient={garnish}
                                                additionalParameters={[garnish.id]}
                                            />
                                        </TableCell>
                                        <TableCell align="right" className={classes.tableCell}>
                                            <InlineEditCurrency
                                                editable={recipe.isEditable}
                                                callback={setIngredientCost}
                                                value={garnish.cost.toFixed(2)}
                                                additionalParameters={[garnish.id]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={2} align="right" className={classes.tableFootCell}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Total Garnish Cost
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableFootCell}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {recipe.garnishCostTotal}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={5} className={classes.imageContainer}>
                    <img alt="Drink in glassware." className={classes.drinkPreview} src={'data:image/png;base64,' + recipe.imageBase64} />
                </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.outerContainer}>
                <Grid item xs={7} className={classes.innerContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.blueLight}>Suggested Selling Price</TableCell>
                                    <TableCell align="center" className={classes.blueLight}>Cost Margin</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'selling-price-1'}>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <InlineEditCurrency
                                            editable={recipe.isEditable}
                                            callback={updateSuggestedSellingPrices}
                                            value={recipe.suggestedSellingPrice.price1.toFixed(2)}
                                            additionalParameters={[1]}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <Typography gutterBottom className={recipe.isEditable ? classes.suggestedSellingPriceText : classes.suggestedSellingPriceView}>{(recipe.suggestedSellingPrice.margin1 * 100).toFixed(1) + "%"}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'selling-price-2'}>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <InlineEditCurrency
                                            editable={recipe.isEditable}
                                            callback={updateSuggestedSellingPrices}
                                            value={recipe.suggestedSellingPrice.price2.toFixed(2)}
                                            additionalParameters={[2]}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <Typography gutterBottom className={recipe.isEditable ? classes.suggestedSellingPriceText : classes.suggestedSellingPriceView}>{(recipe.suggestedSellingPrice.margin2 * 100).toFixed(1) + "%"}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'selling-price-3'}>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <InlineEditCurrency
                                            editable={recipe.isEditable}
                                            callback={updateSuggestedSellingPrices}
                                            value={recipe.suggestedSellingPrice.price3.toFixed(2)}
                                            additionalParameters={[3]}
                                        />
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        <Typography gutterBottom className={recipe.isEditable ? classes.suggestedSellingPriceText : classes.suggestedSellingPriceView}>{(recipe.suggestedSellingPrice.margin3 * 100).toFixed(1) + "%"}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={5} className={classes.innerContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.blueDark}>Drink Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'description'}>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1" className={classes.wrapped}>{recipe.description}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.blueDark}>Instructions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'instructions'}>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Typography variant="body1" className={classes.wrapped}>{recipe.directions}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.blueDark}>Client Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'client-note'}>
                                    <TableCell align="left" className={classes.textAreaTableCell}>
                                        <InlineEditTextArea editable={true} callback={updateClientNote} value={recipe.clientNote} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid container spacing={0}>
                        <Grid item xs={6} style={{ minHeight: '90px' }}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.blueDark}>Created By</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={'instructions'}>
                                            <TableCell align="left" className={classes.tableCell} style={{ height: '56px' }}>
                                                <Typography variant="body1" className={classes.wrapped}>{recipe.createdBy}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.blueDark}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={'client-note'}>
                                            <TableCell align="left" className={classes.textAreaTableCell} style={{ height: '56px' }}>
                                                <ThemeProvider theme={theme}>
                                                    <Select
                                                        className={classes.select}
                                                        value={recipe.statusClient}
                                                        onChange={handleStatusChange}
                                                        variant="outlined"
                                                        autoWidth
                                                        displayEmpty
                                                    >
                                                        <MenuItem key={"status-" + 0} value="">None</MenuItem>
                                                        {null !== statuses && statuses.map(status => <MenuItem key={"status-" + status.value} value={status.value}>{status.value}</MenuItem>)}
                                                    </Select>
                                                </ThemeProvider>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

export default withStyles(styles)(RecipeDetail);
