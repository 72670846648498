import { createStyles, Theme, WithStyles, OutlinedInput } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { useState, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            input: {
                fontSize: "16px",
                fontWeight: 400,
                textAlign: "left",
                width: '400px',
            },
        },
    },
});

const styles = (theme: Theme): StyleRules => createStyles({
    editIcon: {
        color: 'grey',
        paddingLeft: '5px'
    },
    cancelIcon: {
        color: 'red',
    },
    wrapper: {
        minHeight: '200px',
    },
    simple: {
        backgroundColor: '#fff'
    },
    none: {
        display: 'none',
    },
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#ffffff",
        textAlign: "left",
    },
    textEditable: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#ffffff",
        textAlign: "left",
        width: '400px',
    },
    headerText: {
        color: 'white',
        backgroundColor: 'grey',
        width: '400px',
    },
});

type OwnProps = WithStyles<typeof styles> & {
    editable: boolean;
    value: string | number;
    classname?: string;
    classnameEditable?: string;
    formatter?: Function;
    prefix?: string;
    callback: Function;
    additionalParameters?: (string | number)[];
};

type Props = OwnProps;

const InlineEditText = ({ classes, editable, value, callback, additionalParameters, classname, classnameEditable, formatter, prefix }: Props) => {
    const [originalValue] = useState(value);
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const handleBlur = (e: React.FormEvent<EventTarget>) => {
        let value = localValue;

        if ('' === value) {
            setLocalValue(originalValue);
        } else {
            if (value !== originalValue) {
                if (undefined !== additionalParameters) {
                    callback(value, ...additionalParameters);
                } else {
                    callback(value);
                }
            }
        }
    }

    const addClass = undefined === classname ? classes.wrapped : classes[classname]
    const addClassEditable = undefined === classnameEditable ? classes.simple : classes[classnameEditable]

    return (<React.Fragment>
        <span className={classes.wrapper}>
            {!editable && <Typography variant="body1" className={addClass} gutterBottom>
                {prefix}{undefined === formatter ? localValue : formatter(localValue)}
            </Typography>}
            {!!editable && <form className={classes.root} noValidate autoComplete="off">
                <button type="submit" disabled className={classes.none} aria-hidden="true"></button>
                <ThemeProvider theme={theme}>
                    <OutlinedInput
                        margin="dense"
                        id="filled-basic"
                        inputRef={inputRef}
                        className={addClassEditable}
                        onBlur={handleBlur}
                        value={localValue}
                        onChange={e => setLocalValue(e.currentTarget.value)}
                    />
                </ThemeProvider>
            </form>}
        </span>
    </React.Fragment>);
};

export default withStyles(styles)(InlineEditText);
